import cerroVerde from "../assets/images/clientes/cerro-verde.png"
import souththern from "../assets/images/clientes/souththern.jpg"
import zafranal from "../assets/images/clientes/zafranal.png"
import antapaccay from "../assets/images/clientes/antapaccay.jpg"
import lasBambas from "../assets/images/clientes/las-bambas.jpg"
import enel from "../assets/images/clientes/enel.png"
import prodiel from "../assets/images/clientes/prodiel.png"
import soltec from "../assets/images/clientes/soltec.png"
import eiffage from "../assets/images/clientes/eiffage.jpg"
import acciona from "../assets/images/clientes/acciona.gif"
import statkraft from "../assets/images/clientes/statkraft.png"
import aceros from "../assets/images/clientes/aceros.jpg"
import calcesur from "../assets/images/clientes/calcesur.png"
import yura from "../assets/images/clientes/yura.png"
import enaex from "../assets/images/clientes/enaex.png"
import cumbra from "../assets/images/clientes/cumbra.png"
import ossa from "../assets/images/clientes/ossa.png"
import jjc from "../assets/images/clientes/jjc.png"
import intelec from "../assets/images/clientes/intelec.png"
import stracon from "../assets/images/clientes/stracon.jpg"
import carmen from "../assets/images/clientes/carmen.jpeg"
export default [
  {
    img: cerroVerde,
  },
  {
    img: souththern,
  },
  {
    img: zafranal,
  },
  {
    img: antapaccay,
  },
  {
    img: lasBambas,
  },
  {
    img: enel,
  },
  {
    img: prodiel,
  },
  {
    img: soltec,
  },
  {
    img: eiffage,
  },
  {
    img: acciona,
  },
  {
    img: statkraft,
  },
  {
    img: aceros,
  },
  {
    img: calcesur,
  },
  {
    img: yura,
  },
  {
    img: enaex,
  },
  {
    img: cumbra,
  },
  {
    img: ossa,
  },
  {
    img: jjc,
  },
  {
    img: intelec,
  },
  {
    img: stracon,
  },
  {
    img: carmen,
  },
]
